<template>
  <van-action-sheet
    class="login-box"
    v-model="show"
    title="登录/注册"
    :close-on-click-overlay="false"
    @cancel="handleCancel"
    close-icon="clear"
  >
    <div class="login-content">
      <div class="input-box mobile-input-box">
        <div class="input-area">
          <input
            :style="{ color: telWarning ? '#333' : '#333' }"
            type="text"
            v-model="tel"
            maxlength="11"
            placeholder="请输入手机号"
            @blur="inputBlur"
          />
        </div>
        <div class="input-right">
          +86<van-icon class="arrow" name="arrow-down" />
        </div>
      </div>
      <div class="input-box code-input-box">
        <div class="input-area">
          <input
            type="text"
            v-model="code"
            maxlength="6"
            placeholder="请输入验证码"
            @blur="inputBlur"
          />
        </div>
        <div
          class="input-right"
          :style="{ color: isVertify ? '#999C9B' : '#000000' }"
          @click="getCode"
        >
          {{ codeText }}
        </div>
      </div>
      <div class="login-action">
        <van-button
          class="login-btn"
          :style="{
            border: 'none',
            background: loginDisabled
              ? '#f3b79e'
              : 'linear-gradient(90deg, #FF9577 0%, #FF3A20 100%)',
          }"
          :disabled="loginDisabled"
          type="info"
          @click="login"
          >登录</van-button
        >
      </div>
    </div>
  </van-action-sheet>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { ActionSheet, Button, Icon } from "vant";
import mathManage from "../utils/mathManage";
import { getLoginMsg, login, getUserInfo } from "@/api/common";
export default {
  name: "LoginBox",
  components: {
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Icon.name]: Icon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tel: "",
      code: "",
      codeText: "获取验证码",
      num: 60,
      timer: null,
      isVertify: false,
      submitDisabled: false,
    };
  },
  computed: {
    ...mapState(["telReg", "isInWx"]),
    show: {
      get() {
        return this.value;
      },
      set() {
        this.$emit("input", false);
      },
    },
    telWarning() {
      if (!this.tel || !mathManage.regMobile(this.tel)) {
        return true;
      }
      return false;
    },
    loginDisabled() {
      if (this.tel && mathManage.regMobile(this.tel) && this.code) {
        return false;
      }
      return true;
    },
  },
  watch: {
    tel: function (val) {
      this.tel = val.replace(/\D/g, "");
    },
  },
  methods: {
    ...mapMutations(["setLoginShow", "setLoginStatus"]),
    ...mapActions(["dispatchGetCardCount", "getUserInfo"]),
    inputBlur() {
      document.body.scrollIntoView(); // 回顶部
    },
    // 点击取消
    handleCancel() {
      if (this.$route.meta.toHome) {
        this.$router.push("/");
      }
      this.$emit("handleCancel");
    },

    // 获取验证码
    async getCode() {
      if (!this.tel) {
        this.$toast("请输入手机号！");
        return;
      }
      if (this.isVertify) return;
      this.isVertify = true;
      try {
        const res = await getLoginMsg({
          mobile: this.tel,
        });
        if (res && res.code == 0) {
          this.$toast.success("发送成功！");
          this.timer = setInterval(() => {
            if (this.num <= 0) {
              clearInterval(this.timer);
              this.num = 60;
              this.codeText = "获取验证码";
              this.isVertify = false;
            } else {
              this.num = this.num - 1;
              this.codeText = `${this.num}s`;
              this.isVertify = true;
            }
          }, 1000);
        } else {
          this.$toast(res.message);
          this.isVertify = false;
        }
      } catch (error) {
        this.isVertify = false;
      }
    },

    // 点击登录
    async login() {
      if (!this.tel || !this.code) {
        return;
      }
      if (this.submitDisabled) return;
      this.submitDisabled = true;
      if (this.isInWx) {
        this.$wxLogin();
        return;
      }
      const params = {
        mobile: this.tel,
        code: this.code,
      };
      this.$loading("登录中...");
      try {
        const res = await login(params);
        this.$toast.clear();
        this.submitDisabled = false;
        if (res && res.code == 0) {
          this.setLoginStatus({ status: true });
          this.getUserInfo();
          this.dispatchGetCardCount();
          localStorage.setItem("login_time", new Date().getTime());
          localStorage.setItem("access_token", res.data?.access_token);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          setTimeout(() => {
            this.$emit("onOk");
          }, 500);
        } else {
          this.$toast(res.message);
          this.isVertify = false;
        }
      } catch (error) {
        this.$toast.clear();
        this.submitDisabled = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login-box {
  height: 80vh;
  .van-action-sheet__header {
    font-size: 0.36rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
  }

  .van-action-sheet__content {
    overflow: hidden;
  }
  .login-content {
    padding: 0.36rem 0.48rem;
    box-sizing: border-box;

    .input-box {
      height: 0.96rem;
      background: #f4f6f8;
      border-radius: 0.08rem;
      display: flex;

      .input-area {
        flex: 1;
        overflow: hidden;

        input {
          height: 100%;
          width: 100%;
          font-size: 0.28rem;
          display: block;
          border: 0;
          outline: none;
          background-color: rgba(0, 0, 0, 0);
          display: flex;
          align-items: center;
          padding: 0 0.32rem;
          box-sizing: border-box;
        }
      }

      .input-right {
        width: 1.8rem;
        height: 100%;
      }
    }

    .mobile-input-box {
      .input-right {
        font-size: 0.3rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #999c9b;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .arrow {
          margin: 0 0.36rem 0 0.12rem;
          font-size: 0.24rem;
        }
      }
    }

    .code-input-box {
      margin-top: 0.24rem;

      .input-right {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.24rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
      }
    }

    .warning-tip {
      height: 0.2rem;
      color: red;
      font-size: 0.18rem;
      margin-top: 0.04rem;
      padding-left: 0.34rem;
    }

    .login-action {
      margin-top: 0.72rem;

      .login-btn {
        width: 100%;
        height: 0.96rem;
        background: #f3b79e;
        border-radius: 0.16rem;
        border-color: #f3b79e;
        font-size: 0.28rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        letter-spacing: 0.06rem;
      }
    }
  }
}
</style>
