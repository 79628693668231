<template>
  <div class="account-page">
    <div class="account-header">
      <!-- <div class="change-box" @click="toSetting">
        <van-icon name="setting-o" class="replay-icon" />
      </div> -->
      <div class="user-info-box">
        <div class="user-icon">
          <img
            :src="
              isLogin
                ? require('../assets/images/user_icon.png')
                : require('../assets/images/user_icon_gray.png')
            "
            alt=""
          />
        </div>
        <div class="user-msg">
          <div v-if="isLogin" class="user-name">
            {{ userInfo.mobile || "-" }}
          </div>
          <div v-else class="user-name" @click="clickLogin">
            立即登录<van-icon name="arrow" class="arrow-icon" />
          </div>
          <div class="user-uid">
            {{
              isLogin
                ? `UID: ${userInfo.userCode || "-"}`
                : "登录后查看账户信息"
            }}
          </div>
        </div>
      </div>
      <div class="balance-box">
        <div class="my-balance">
          <div class="title">我的卡券</div>
          <div class="money">
            <div class="icon">
              <img src="../assets/images/juan.png" />
            </div>
            <span class="text">{{ isLogin ? cardCount : "-" }}</span>
          </div>
        </div>
        <div class="show-digital" @click="viewAlljuan">
          <span class="text" :style="{ color: isLogin ? '#fff' : '#8990a1' }"
            >查看全部卡券</span
          >
          <van-icon
            name="arrow"
            :style="{ color: isLogin ? '#fff' : '#8990a1' }"
            class="arrow-icon"
          />
        </div>
      </div>
    </div>
    <div class="account-main">
      <div class="order-tab-box">
        <div class="tab-title">
          <span class="order-title">我的订单</span>
          <div class="to-all-order" @click="clickMenu({ id: '' })">
            全部订单 <van-icon class="arrow" name="arrow" />
          </div>
        </div>
        <div class="tab-list">
          <van-grid class="menu-grid" :column-num="4" :border="false">
            <van-grid-item
              v-for="item in menuList"
              :key="item.id"
              :text="item.name"
              @click="clickMenu(item)"
              :badge="
                isLogin ? (item.count > 99 ? '99+' : item.count || '') : ''
              "
            >
              <template slot="icon">
                <!-- <icon-svg :iconClass="item.icon" class="tab-svg"></icon-svg> -->
                <div class="icon-box">
                  <img :src="item.icon" alt="" />
                </div>
              </template>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
      <div v-if="!isLogin">
        <div class="you-like-box">
          <div class="like-icon">
            <img src="../assets/images/like.png" alt="" />
          </div>
          <span class="like-title">猜你喜欢</span>
        </div>
        <div class="like-goods-box">
          <!-- <DigitalGoods :showFaceValue="true" /> -->
          <product-list :data="goodsList" />
        </div>
      </div>
    </div>
    <div v-if="isLogin" class="action-box">
      <van-cell-group inset>
        <van-cell :value="userInfo.mobile" @click="chengeMobile">
          <template #title>
            <img class="action-box-icon" src="../assets/images/phone.png" />
            <span class="custom-title">更换手机号</span>
          </template>
        </van-cell>
        <van-cell
          v-for="(item, i) in serviceList"
          :key="i"
          :value="(item.type != 90 && item.number) || ''"
          :url="item.href"
          :is-link="item.click"
          @click="serviceAction(item)"
        >
          <template #title>
            <img class="action-box-icon" :src="item.src" />
            <span class="custom-title">{{ item.name }}</span>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div v-if="isLogin" class="exit-login" @click="showLoginOut = true">
      <img class="exit-login-icon" src="../assets/images/tuichu.png" />
      <span>退出登录</span>
    </div>
    <van-popup v-model="showLoginOut" :close-on-click-overlay="false" round>
      <div class="confirm-box">
        <h2>确认退出登录？</h2>
        <div class="cancel-action">
          <span class="cancel-cancel" @click="showLoginOut = false">取消</span>
          <span class="cancecl-ok" @click="loginOut">确认</span>
        </div>
      </div>
    </van-popup>
    <login-box
      v-model="showLogin"
      @handleCancel="handleCancelLogin"
      @onOk="loginOk"
    />
    <change-mobile
      v-model="showMobile"
      @onCancel="cancelChangeMobile"
      @onOk="chengMobileConfirm"
      :step="step"
      :oldMobile="oldMobile"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { Icon, Grid, GridItem, Cell, CellGroup, Dialog, Popup } from "vant";
import IconSvg from "../components/IconSvg.vue";
import DigitalGoods from "../components/DigitalGoods.vue";
import "../assets/images/svg/integral.svg";
import LoginBox from "../components/LoginBox.vue";
import ChangeMobile from "../components/ChangeMobile.vue";
import { digitalproductpage, getOrderList } from "@/api/common";
import ProductList from "@/components/ProductList.vue";

export default {
  name: "Account",
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Icon.name]: Icon,
    IconSvg,
    DigitalGoods,
    LoginBox,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    ChangeMobile,
    ProductList,
  },
  data() {
    return {
      showMobile: false,
      showLoginOut: false,
      step: 1,
      menuList: [
        {
          id: "10",
          name: "待付款",
          icon: require("../assets/images/order2.png"),
          count: 0,
        },
        {
          id: "20",
          name: "已支付",
          icon: require("../assets/images/order3.png"),
          count: 0,
        },
        {
          id: "30",
          name: "已完成",
          icon: require("../assets/images/order4.png"),
          count: 0,
        },
        {
          id: "40",
          name: "售后管理",
          icon: require("../assets/images/service-icon.png"),
          count: 0,
        },
      ],
      showLogin: false,
      oldMobile: "",
      goodsList: [],
    };
  },
  computed: {
    ...mapState([
      "isLogin",
      "cardCount",
      "userInfo",
      "isInWx",
      "isMiniProgram",
      "serviceList",
    ]),
  },
  created() {
    document.title = "我的";
  },
  mounted() {
    if (!this.isLogin) {
      this.getDataList();
    } else {
      this.getPayOrderCount();
    }
  },
  methods: {
    ...mapMutations(["setLoginStatus", "setUserInfo", "setCardCount"]),
    ...mapActions(["dispatchGetCardCount", "getUserInfo", "serviceAction"]),
    // 获取待付款订单数量
    async getPayOrderCount() {
      const res = await getOrderList({
        pageSize: 1,
        pageIndex: 1,
        OrderStatus: 10,
      });
      if (res && res.code == 0) {
        this.menuList = this.menuList.map((item) => {
          if (item.id == 10) {
            return {
              ...item,
              count: res.data.total || 0,
            };
          }
          return item;
        });
      }
    },

    // 点击登录
    clickLogin() {
      if (this.isInWx) {
        this.$wxLogin();
        return;
      }
      this.showLogin = true;
    },

    // 成功登录
    loginOk() {
      this.showLogin = false;
      this.getPayOrderCount();
      // this.dispatchGetCardCount();
      // this.getUserInfo();
    },

    // 取消登录
    handleCancelLogin() {
      this.showLogin = false;
    },

    // 设置
    toSetting() {
      this.$router.push("/mall/setting");
    },

    // 点击订单tab
    clickMenu(item) {
      if (item.id == 40) {
        this.$router.push("/mall/serviceList");
        return;
      }
      this.$router.push({
        path: "/mall/orderList",
        query: {
          status: item.id,
        },
      });
    },

    // 查看积分明细
    viewAlljuan() {
      this.$router.push("/mall/ticket");
    },

    // 获取猜你喜欢列表
    async getDataList() {
      const res = await digitalproductpage({
        ProductTag: configs.tagProduct[2],
        PageIndex: 1,
        PageSize: 4,
        ProductStatus: 10,
      });
      if (res && res.code == 0) {
        this.goodsList = res.data.list || [];
      } else {
        this.$toast(res.message);
      }
    },

    // 修改手机号
    chengeMobile() {
      this.showMobile = true;
    },

    cancelChangeMobile() {
      this.showMobile = false;
      this.step = 1;
      this.oldMobile = "";
    },

    chengMobileConfirm(step, oldMobile) {
      if (step == 1) {
        this.step = 2;
        this.oldMobile = oldMobile;
        return;
      }
      this.showMobile = false;
      localStorage.clear();
      this.setLoginStatus({ status: false });
      this.setCardCount(0);
      this.setUserInfo({ userInfo: {} });
      this.showLogin = true;
    },

    loginOut() {
      this.showLoginOut = false;
      localStorage.clear();
      this.setLoginStatus({ status: false });
      this.setCardCount(0);
      this.setUserInfo({ userInfo: {} });
      this.getDataList();
    },
  },
};
</script>

<style lang="less" scoped>
.account-page {
  height: 100%;
  overflow-y: auto;

  .account-header {
    height: 4.12rem;
    background-image: url("../assets/images/account_bg.png");
    background-size: 100% 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;

    .change-box {
      position: absolute;
      right: 0.32rem;
      top: 0.32rem;
      color: #fff;
      opacity: 0.8;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .replay-icon {
        margin-left: 0.08rem;
        font-size: 0.35rem;
      }
    }

    .user-info-box {
      height: 1.2rem;
      padding: 0 0.32rem;
      display: flex;
      overflow: hidden;
      margin-top: 0.88rem;

      .user-icon {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 0.32rem;
        background-color: #fff;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .user-msg {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .user-name {
          font-size: 0.36rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          display: flex;
          align-items: center;

          .arrow-icon {
            margin-left: 0.04rem;
          }
        }

        .user-uid {
          font-size: 0.24rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.8;
          margin-top: 0.08rem;
        }
      }
    }

    .balance-box {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      width: 6.86rem;
      height: 1.48rem;
      background: #1f273c;
      border-radius: 0.24rem 0.24rem 0rem 0rem;
      padding: 0 0.24rem 0.12rem 0.48rem;
      display: flex;
      justify-content: space-between;

      .my-balance {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8990a1;
        }

        .money {
          display: flex;
          align-items: center;
          margin-top: 0.08rem;

          .icon {
            width: 0.28rem;
            height: 0.28rem;
            // background: #ffeca4;
            // border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            > img {
              width: 100%;
              height: 100%;
            }
          }
          .text {
            font-size: 0.4rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #f6f7f7;
            margin-left: 0.12rem;
          }
        }
      }

      .show-digital {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .text {
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #f6f7f7;
        }

        .arrow-icon {
          font-size: 0.26rem;
          color: #f6f7f7;
          margin-left: 0.04rem;
        }
      }
    }
  }

  .account-main {
    // min-height: calc(100vh - 4.12rem);
    background-color: #fff;
    position: relative;
    z-index: 2;
    top: -0.16rem;
    border-radius: 0.24rem 0.24rem 0rem 0rem;
    padding: 0.4rem;
    box-sizing: border-box;

    .order-tab-box {
      .tab-title {
        height: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .order-title {
          font-size: 0.28rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #303133;
        }

        .to-all-order {
          display: flex;
          align-items: center;
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;

          .arrow {
            margin-left: 0.2rem;
          }
        }
      }
      .tab-list {
        .menu-grid {
          /deep/ .van-info {
            top: 0.2rem;
          }
          .icon-box {
            width: 0.8rem;
            height: 0.8rem;

            img {
              width: 100%;
              height: 100%;
            }
          }

          /deep/ .van-grid-item__text {
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            margin-top: 0.3rem;
          }
        }
      }
    }

    .you-like-box {
      width: 100%;
      height: 0.4rem;
      display: flex;
      align-items: center;
      overflow: hidden;
      margin-top: 0.4rem;

      .like-icon {
        width: 0.32rem;
        height: 0.32rem;
        display: flex;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .like-title {
        font-size: 0.28rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303133;
        margin-left: 0.16rem;
      }
    }

    .like-goods-box {
      margin: 0.4rem 0;
    }
  }

  .action-box {
    background: #ffffff;
    padding-right: 0.08rem;

    .action-box-icon {
      width: 0.3rem;
      height: 0.34rem;
      margin-right: 0.4rem;
      margin-top: 0.06rem;
    }

    .service {
      margin-left: 0.02rem;
      margin-right: 0.4rem;
      margin-top: 0.09rem;
      font-size: 0.3rem;
    }

    /deep/.van-cell__title {
      display: flex;
    }

    .custom-title {
      font-size: 0.28rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
  }

  .exit-login {
    height: 0.92rem;
    background: #ffffff;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.2rem;

    .exit-login-icon {
      width: 0.23rem;
      height: 0.24rem;
      margin-right: 0.16rem;
    }
  }

  /deep/.van-overlay {
    z-index: 99999;
  }

  .confirm-box {
    width: 5.9rem;
    height: 2.88rem;
    background: #ffffff;
    border-radius: 0.2rem;
    padding: 0 0.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: 0.32rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      text-align: center;
    }
    .cancel-action {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.72rem;

      span {
        width: 2.4rem;
        height: 0.72rem;
        border-radius: 0.36rem;
        border: 0.02rem solid #e8e8e8;
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.01rem;

        &.cancecl-ok {
          background: #ff4800;
          border-color: #ff4800;
          color: #fff;
        }
      }
    }
  }
}
</style>
